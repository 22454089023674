<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      loading: false,
      userinfo: [],
      micoapplist: [],
    };
  },
  mounted() {
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    this.title = this.$t("menuitems.icp.micoapp.list");
    this.items = [
      {
        text: this.$t("menuitems.icp.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.icp.micoapp.list"),
        active: true,
      },
    ];
    this.getmicoapplist();
  },
  methods: {
    getmicoapplist() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "getmicoapplist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.micoapplist = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    makeicp(appid) {
      this.$router.push({
        path: "/icp/micoapp/" + appid,
      });
    },
    cancelicp(appid) {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          "https://api.aicoiot.com/componentapi.php",
          {
            action: "cancelicp",
            appid: appid,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.$message({
            message: response.data.msg,
            type: "success",
          });
          that.getmicoapplist();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <el-table :data="micoapplist" stripe style="width: 100%">
              <el-table-column prop="niconame" label="名称"> </el-table-column>
              <el-table-column prop="appid" label="AppID"></el-table-column>
              <el-table-column prop="icpcode" label="备案号"></el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row.niconame">
                    <el-button type="text" size="small"
                      v-if="scope.row.icpcode && scope.row.icpstatus == 1">注销</el-button>
                    <el-button type="text" size="small" @click="makeicp(scope.row.appid)"
                      v-if="!scope.row.icpcode && scope.row.icpstatus == 0">备案</el-button>
                    <el-button type="text" size="small" @click="cancelicp(scope.row.appid)"
                      v-if="!scope.row.icpcode && scope.row.icpstatus == 2">取消</el-button>
                  </div>
                  <div v-else>
                    请先完善小程序信息
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>